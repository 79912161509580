<template>
  <div>
    <TableInvitatiConsigliere
      @getList="get_people"
      @resetFilters="resetFilters"
      :objectList="people"
      :loaded="loaded_people"
      :status="status_people"
      @setPayload="setPayload"
      :payload="payload"
    />
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/functions/trimInput";
import TableInvitatiConsigliere from "@/components/invitations/TableInvitatiConsigliere.vue";
import { useApiCall } from "@/composables/apiCall";
import { API } from "@/enums/costanti";
export default defineComponent({
  name: "sanzioni",
  components: {
    TableInvitatiConsigliere,
  },
  props: {
    id_person: {
      type: Number,
    },
    id_year: {
      type: Number,
    },
    id_event: {
      type: Number,
    },
    id_user: {
      type: String,
    },
  },
  setup(props) {
    const payload = ref({
      id_person: props.id_person,
      id_year: props.id_year,
      id_event: props.id_event,
      id_user: props.id_user,
      rowstoskip: 0,
      fetchrows: 25,
    });
    
    const setPayload = ({ name, value }) => {
      payload.value[name] = value;
    };
    const resetFilters = () => {
      payload.value = { rowstoskip: 0, fetchrows: 25 };
      get_people();
    };

    const {
      element: people,
      loaded: loaded_people,
      getElement: get_people,
      status: status_people,
    } = useApiCall(API.INVITATIONS_CF_LIST, payload);
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Inviti Collaboratore", payload);
      await get_people();
    });
    return {
      resetFilters,
      setPayload,
      get_people,
      people,
      loaded_people,
      status_people,
      trimInput,
      payload,
    };
  },
});
</script>

<script></script>
<style></style>
