<template>
  <Datatable
    :key="loaded"
    :table-header-2="tableHeader2"
    :table-header="tableHeader"
    :table-data="objectList ? objectList.results : []"
    :status="status"
    :loading="!loaded"
    :enableItemsPerPageDropdown="false"
    :total="objectList ? objectList.record : 0"
    :rowsPerPage="1000"
    :currentPage="currentPage"
    :order="payload.sortorder"
    :sortLabel="payload.sortcolumn"
  >
    <template v-slot:cell-cognome="{ row: data }"
      ><div class="">
        {{ data.cognome }}
      </div>
    </template>
    <template v-slot:cell-nome="{ row: data }"
      ><div class="d-flex" style="position: relative">
        {{ data.nome }}
      </div>
    </template>
    <template v-slot:cell-data_invito="{ row: data }">
      <div class="tab-long d-flex">
        <div class="">{{ data.data_invito }}</div>
      </div>
    </template>
    <template v-slot:cell-status="{ row: data }">
      <div class="tab-long d-flex justify-content-between">
        <div class="">
          <h1
            class="mb-0 badge"
            :class="
              data.stato === 'confermato'
                ? 'badge-light-success'
                : data.stato === 'nuovo'
                ? 'badge-light-primary'
                : data.stato === 'rifiutato'
                ? 'badge-light-danger'
                : data.stato === 'inviato'
                ? 'badge-light-primary'
                : data.stato === 'da confermare'
                ? 'badge-light-accettato'
                : data.stato === 'bozza'
                ? 'badge-light-warning'
                : data.stato === 'consiglio federale'
                ? 'badge-light-federali'
                : data.stato === 'da confermare C.F. / PRES. CCRR'
                ? 'badge-light-da-confermare-ccrr'
                : ''
            "
          >
            {{ data.stato }}
          </h1>
        </div>
        <div class="btn btn-sm dropdown p-1">
          <button
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#TorneiRaas_panel_${data.id_invitation}`"
            aria-expanded="true"
            :aria-controls="`#TorneiRaas_panel_${data.id_invitation}`"
            v-if="data.guest && data.guest.length"
          >
            <i class="bi bi-person-down text-dark fs-4"></i>
          </button>
          <Popper arrow class="ms-2">
            <button type="button">
              <i class="bi bi-info-circle-fill text-center"></i>
            </button>
            <template #content>
              <div>Sessioni:</div>
              <div
                v-for="sessione in data.sessioni"
                :key="sessione.id"
                style="min-width: 10rem"
              >
                {{ sessione.session_name }}
              </div>
            </template>
          </Popper>
        </div>
      </div>
    </template>
    <template v-slot:cell-accompagnatori="{ row: data, index: idx }">
      <div>
        <div
          :id="`TorneiRaas_panel_${data.id_invitation}`"
          class="accordion-collapse collapse"
          :aria-labelledby="`TorneiRaas_int_${data.id_invitation}`"
          :style="
            idx % 2 == 0
              ? 'background-color: #f8fafb'
              : 'background-color: #ffffff'
          "
        >
          <div class="accordion-body">
            <table class="table tablegy-3 gs-7 align-top">
              <thead>
                <tr
                  class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                >
                  <th>Cognome</th>
                  <th>Nome</th>
                  <th>Info</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(guest, index) in data.guest" :key="index">
                  <td>{{ guest.surname }}</td>
                  <td>{{ guest.name }}</td>
                  <td>
                    <div class="btn btn-sm dropdown p-1">
                      <Popper arrow class="ms-2">
                        <button type="button">
                          <i class="bi bi-info-circle-fill text-center"></i>
                        </button>
                        <template #content>
                          <div>Sessioni:</div>
                          <div
                            v-for="sessione in guest.sessioni"
                            :key="sessione.id"
                            style="min-width: 10rem"
                          >
                            {{ sessione.session_name }}
                          </div>
                        </template>
                      </Popper>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class=""></div>
          </div>
        </div>
      </div>
    </template>
  </Datatable>
</template>
<script>
import Datatable from "@/components/kt-datatable/KTDatatableSottosezione.vue";
import { computed, ref, watch } from "vue";
import { formatDateITANoTime } from "@/functions/formatDate.js";
import Popper from "vue3-popper";

export default {
  name: "TableInvitatiConsigliere",
  components: {
    Datatable,
    Popper,
  },
  props: {
    objectList: {
      type: Object,
    },
    loaded: {
      type: Boolean,
    },
    status: {
      type: Number,
    },
    payload: {
      type: Object,
    },
  },
  setup(props) {
    const tableHeader = ref([
      {
        name: "Cognome",
        key: "cognome",
      },
      {
        name: "Nome",
        key: "nome",
      },
      {
        name: "Data invito",
        key: "data_invito",
      },
      {
        name: "Status",
        key: "status",
      },
    ]);
    const tableHeader2 = ref([
      {
        name: "Accompagnatori",
        key: "accompagnatori",
      },
    ]);

    const length = computed(() =>
      props.objectList &&
      props.objectList.results &&
      props.objectList.results.length
        ? props.objectList.results.length
        : 0
    );
    
    
    watch(length, () => {
      
      // Seleziona tutti gli elementi con la classe 'popper'

      const popperElements = document.querySelectorAll(".popper");

      

      // Itera su ciascun elemento e aggiungi la nuova classe
      popperElements.forEach((element) => {
        element.classList.add("position-fixed");
      });
    });

    return { tableHeader, formatDateITANoTime, tableHeader2 };
  },
};
</script>
<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
:deep(.popper) {
  /* position: fixed !important; */
  /* top: -100px !important;
  left: 0 !important;
  transform: translateY(-100%) !important; */
}
</style>
